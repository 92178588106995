export function pageNavigation() {
	const navMenu = document.querySelector('[data-page-navigation-menu]')

	if (!navMenu) return

	const debounceMS = 50
	const html = document.documentElement
	const navMenuLinks = navMenu.querySelectorAll('a')
	const pageSections = []
	const navMenuOffset = document.querySelector('[data-page-navigation-menu-offset]')
	const offsetElements = document.querySelectorAll('[data-page-navigation-offset]')
	const scrollSnap = navMenu.hasAttribute('data-page-navigation-scroll-snap')

	let scrollPaddingTop
	let navMenuOffsetTop
	let currentActiveSection
	let currentActiveLink
	let scrollSnapPaddingLeft

	findMatchingSectionsForNavMenuLinks()

	if (scrollSnap) {
		const scrollSnapComputedStyle = getComputedStyle(navMenu)
		scrollSnapPaddingLeft = parseFloat(scrollSnapComputedStyle.paddingLeft)
	}

	const pageSectionsReversed = pageSections.slice().reverse()
	const lastPageSection = pageSections.at(-1)

	setDynamicOffsets()
	pageNavigationInit()

	function findMatchingSectionsForNavMenuLinks() {
		navMenuLinks.forEach(link => {
			const hrefValue = link.getAttribute('href')
			const idValue = hrefValue.substring(1)
			const section = document.getElementById(idValue)

			pageSections.push(section)
		})
	}

	function makeLinkActive() {
		currentActiveLink = navMenuLinks[currentActiveSection]
		currentActiveLink?.classList.add('active')
		scrollToActiveLink()
	}

	function scrollToActiveLink() {
		if (!scrollSnap) return

		if (currentActiveLink) {
			navMenu.scrollLeft = currentActiveLink.offsetLeft - scrollSnapPaddingLeft
		}
	}

	function removeActiveClass() {
		navMenu.querySelector('.active')?.classList.remove('active')
	}

	function handleScroll() {
		const nextSection = pageSectionsReversed.findIndex(
			section => window.scrollY >= section.offsetTop - scrollPaddingTop
		)

		const currentSection = pageSections.length - nextSection - 1
		const isPastLastPageSection = window.scrollY > lastPageSection.offsetTop + lastPageSection.offsetHeight

		if (isPastLastPageSection) {
			removeActiveClass()
			currentActiveSection = null
			return
		}

		if (currentSection !== currentActiveSection) {
			currentActiveSection = currentSection
			removeActiveClass()
			makeLinkActive()
		}
	}

	function setDynamicOffsets() {
		const resizeObserver = new ResizeObserver(entries => {
			scrollPaddingTop = 0
			navMenuOffsetTop = 0

			for (const entry of entries) {
				const elementHeight = entry.contentBoxSize[0].blockSize
				scrollPaddingTop += elementHeight

				if (entry.target != navMenuOffset) {
					navMenuOffsetTop += elementHeight
				}
			}

			navMenuOffset?.style.setProperty('top', `${Math.round(navMenuOffsetTop - 1)}px`)
			html.style.setProperty('scroll-padding-top', `${Math.round(scrollPaddingTop - 2)}px`)
		})

		resizeObserver.observe(navMenuOffset)

		offsetElements.forEach(element => {
			resizeObserver.observe(element)
		})
	}

	function pageNavigationInit() {
		const activeLinkHandler = debounce(handleScroll, debounceMS)
		window.addEventListener('scroll', activeLinkHandler)
	}

	function debounce(func, wait) {
		let timeout

		return function (...args) {
			const context = this

			clearTimeout(timeout)
			timeout = setTimeout(() => {
				func.apply(context, args)
			}, wait)
		}
	}
}
